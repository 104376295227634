<template>
  <div>
    <Multiselect
        :searchable="true"
        @search-change="searchByName"
        placeholder="Начните вввод для поиска..."
        selectLabel=""
        deselectLabel=""
        selectedLabel="Выбрано"
        noResult="Не найдено."
        label="name"
        track-by="id"
        v-model="selected"
        :options="sites"
        :multiple="true"
    >
      <template #noResult="slotProps">Ничего не найдено.</template>
    </Multiselect>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, toRef, toRefs, computed} from 'vue';
// @ts-ignore
import Multiselect from "vue-multiselect";
import {useSearch} from "../../logic/banner-site/banner-site-search";
import {Api}            from "../../types/api/v1/electrification";
import BannerSite = Api.V1.BannerSite;

export default defineComponent({
  name      : 'DropdownMenu',
  emits     : ['update:modelValue'],
  props     : {
    modelValue: {
      type: Array
    },
  },
  components: {Multiselect},
  setup(props, vm) {
    const {sites, sitesReady, request, setSites, searchByName} = useSearch();

    const modelValue = toRef(props, 'modelValue');

    // @ts-ignore
    const selected = computed({
      get: () => modelValue.value,
      set: (val: Array<BannerSite>) => {
        vm.emit('update:modelValue', val);
      }
    })

    onMounted(() => searchByName(''))

    return {...toRefs(request), sites, sitesReady, setSites, searchByName, selected}
  },
});
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.css"></style>
<style src="@/../public/style/multiselect-overrides.css">
</style>
