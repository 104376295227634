import ApiClient                      from "../client/ApiClient";
import {AxiosInstance, AxiosResponse} from "axios";
import {Api}                          from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import UrlLoc                         from "./UrlLoc";
import qs                             from "qs";
import LaravelSearchRequest = Api.V1.LaravelSearchRequest;

export default class ApiSearcher<T, R> {
    private _client: AxiosInstance;

    constructor(private urlLoc: UrlLoc) {
        this._client = ApiClient.init();
    }

    public index(request: LaravelSearchRequest<R> | null = null): Promise<LaravelIndexResponse<T>> {
        return this._client.get(this.getIndexUrl(), {
            params: request ?? {},
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then((response: AxiosResponse<LaravelIndexResponse<T>>) => {
            return response.data;
        })
    }


    protected getIndexUrl(): string {
        return this.urlLoc.indexUrl;
    }
}