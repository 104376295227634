import ApiClient                      from "../client/ApiClient";
import {AxiosInstance, AxiosResponse} from "axios";
import UrlLoc                         from "./UrlLoc";

export default class ApiEditAction<T> {
    private _client: AxiosInstance;

    constructor(private urlLoc: UrlLoc) {
        this._client = ApiClient.init();
    }

    public edit(id: number | string, model: T): Promise<{data: T}> {
        return this._client.patch(this.getEditUrl(id), model).then((response: AxiosResponse<{data: T}>) => {
            return response.data;
        })
    }


    protected getEditUrl(id: number | string): string {
        return this.urlLoc.editUrl + '/' + id;
    }
}