import {ref, reactive, computed} from 'vue';
import {Api}                     from "../../types/api/v1/electrification";
import BannerGroupUrlLoc         from "../../services/api/banner-group/BannerGroupUrlLoc";
import BannerGroupAdd = Api.V1.BannerGroupAdd;
import ApiStoreAction            from "../../services/api/ApiStoreAction";
import BannerSite = Api.V1.BannerSite;
import BannerGroupForm = Api.V1.BannerGroupForm;
import ApiEditAction             from "../../services/api/ApiEditAction";
import ApiShowAction             from "../../services/api/ApiShowAction";


export const useBannerGroup = () => {

    const groupId = ref<Number | null>(null);
    const group = ref<BannerGroupForm>({
        sites: [],
        alias: '',
        name : '',
    });

    const nameErrors  = ref<Array<String>>([]);
    const sitesErrors = ref<Array<String>>([]);
    const aliasErrors = ref<Array<String>>([]);

    const editAction = new ApiEditAction<BannerGroupAdd>(new BannerGroupUrlLoc());
    const storeAction = new ApiStoreAction<BannerGroupAdd>(new BannerGroupUrlLoc());
    const showAction = new ApiShowAction<BannerGroupForm>(new BannerGroupUrlLoc());


    const setGroupId = (value: number) => {
        showAction.show(value).then((groupForm: { data: BannerGroupForm }) => {
            group.value   = groupForm.data
            groupId.value = value;
        });
    }


    const save = (): Promise<boolean> => {
        return (!groupId.value) ? saveNew() : saveExist();
    }


    const saveNew = () => {
        return storeAction.create(saveForm.value).then(() => {
            return Promise.resolve(true);
        }, (error) => {
            fetchErrors(error.response.data.errors)
            return Promise.resolve(false);
        });
    }


    const saveExist = () => {
        // @ts-ignore
        return editAction.edit(groupId.value, saveForm.value).then((response: { data: BannerGroupForm }) => {
            group.value = response.data
            return Promise.resolve(true);
        }, (error) => {
            fetchErrors(error.response.data.errors)
            return Promise.resolve(false);
        });
    }

    const saveForm = computed<BannerGroupAdd>((): BannerGroupAdd => {
        return {
            name : group.value.name,
            sites: group.value.sites.map((site: BannerSite) => site.id),
            alias: group.value.alias
        }
    })


    const fetchErrors = (errors: { sites: Array<string>, name: Array<string>, alias: Array<string> }) => {
        errors.hasOwnProperty('sites') && (sitesErrors.value = errors.sites);
        errors.hasOwnProperty('name') && (nameErrors.value = errors.name);
        errors.hasOwnProperty('alias') && (aliasErrors.value = errors.alias);
    }

    return {nameErrors, sitesErrors, aliasErrors, setGroupId, save, group};
};