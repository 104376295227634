import {reactive, ref} from 'vue';
import {Api}            from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import BannerSite = Api.V1.BannerSite;
import BannerSiteUrlLoc from "../../services/api/banner-site/BannerSiteUrlLoc";
import ApiSearcher      from "../../services/api/ApiSearcher";
import BannerSiteFilter = Api.V1.BannerSiteFilter;
import LaravelSearchRequest = Api.V1.LaravelSearchRequest;

export const useSearch = () => {
    const sites      = ref<Array<BannerSite>>([]);
    const sitesReady = ref<boolean>(true);
    const request = reactive<LaravelSearchRequest<BannerSiteFilter>>({
        filter: {},
        sort: null,
        include : null,
        page: 1,
    });

    const fetcher = new ApiSearcher<BannerSite, BannerSiteFilter>(new BannerSiteUrlLoc());

    // @ts-ignore
    const setSites = (value: Array<BannerSite>) => sites.value = value;

    const searchSites = () => {
        return fetcher.index(request).then((data: LaravelIndexResponse<BannerSite>) => {
            setSites(data.data);
            sitesReady.value = true;
        });
    }


    const searchByName = (query: string) => {
        // @ts-ignore
        request.filter.name = query;

        return searchSites();
    }

    return {sites, sitesReady, request, setSites, searchSites, searchByName};
};